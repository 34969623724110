import { Env } from 'typings'

export const environment: Partial<Env> = {
  production: true,
  domain: ".vg-thermostat.com",
  invitationUrl: "https://auth.vg-thermostat.com/invitation/home/",
  authUrl: "https://auth.vg-thermostat.com/access/login/",
  apiUrl: "https://app.netatmo.net/api/",
  syncApiUrl: "https://app.netatmo.net/syncapi/v1/",
  cookiePrefix: "vgthermostatcom",
  webPusherUrl: "wss://app.netatmo.net/ws/",
  langsUrl: "/langs/",
  helpcenterUrl: "https://helpcenter.netatmo.com/hc/",
  appName: "app_thermostat_vaillant",
  myAccountUrl: "https://myconnectedaccount.vg-thermostat.com/",
  faqUrl: "https://settings.vg-thermostat.com/#/faq",
  app: "eRelax",
  mapboxAccessToken: 'pk.eyJ1IjoibmV0YXRtbyIsImEiOiJjanh5Z2U3NmIwMDQ2M2xzOTFsaXA0M2k2In0.uxP5nswLVJvjcm-AsGEPfQ',
};
